import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import classes from './header.module.css'
import { TotalSumContext } from '../../context/allContext';

const AviatorStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
}

const TotalSumStyle = {
    color:'#6d6b78',
    fontFamily: 'Roboto',
    fontWeight:'300',
    fontSize:'12px',
}
const TRYstyle= {
    color:'#2b9017',
    fontWeight:'700',
    fontSize:'16px',
}

const Header = () => {
    const {TotalSum, setTotalSum} = useContext(TotalSumContext)

  return (
    <header className={classes.header}>
        

        
    <div className="aviator" style={AviatorStyle}>
        <div> <img src="./media/logo.svg" alt=""/></div>
        <div className={classes.how_to_play}>
            <img src="./media/question.svg" alt=""/> 
        </div>
    </div>
    <div className={classes.right_up_icons}>
        
        <div className="total_sum" id="total_sum_header"style={TotalSumStyle}><span style={TRYstyle} id="span_amount">{TotalSum}</span> TRY</div>
        <div className={classes.divider_new}></div>
        <div className={classes.menu_icon}>
            <FontAwesomeIcon icon={faBars} className="fa-solid fa-bars" style={{color:"#6d6b78"}}/>
        </div>
        <div className={classes.divider_new}></div>
        <div className="message_icon" style={{alignItems: "center", display: "flex", marginBottom:'14px', marginRight:'5px'}} >
            <a style={{height:'100%', width:'100%'}} href='/account'>
            {<FontAwesomeIcon icon={faMessage} className="fa-solid fa-message" style={{color:"#6d6b78", height:'15px', alignSelf:'center'}}/> }</a>
            
        </div>
    </div>
</header>
  )
}

export default Header