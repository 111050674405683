import PreviousCoefs from './previousCoefs/PreviousCoefs'
import Game from './GameSection/Game'
import React , {useContext, useEffect, useState}from 'react'
import MakeBids from './MakeBids'
import classes from './rightsection.module.css'



const RightSection = ({  fakeCoefs}) => {   
  
  return (
    
      <div style={{flexBasis: "75%"}} className={classes.right}> 
          <PreviousCoefs />
          <Game fakeCoefs = {fakeCoefs} />
          <MakeBids/>
      </div>
      
    
  )
}

export default RightSection